<template>
  <div>
    <!-- Start Header Area -->
    <HeaderTwo class="designer-portfolio-header">
      <img slot="logo" :src="logo" alt="Creative Agency Logo" />
    </HeaderTwo>
    <!-- End Header Area -->

    <div class="btn-hire">
      <router-link class="btn-default" to="/contact">Hire Me</router-link>
    </div>

        <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image bigdata-bg"
      data-black-overlay="1"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">Reference</h2>
              <p>
                
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Gallery Area  -->
    <div class="designer-portfolio-area ptb--120 bg_color--1">
      <v-container>
        <div class="rn-masonary-wrapper">
          <div class="wrapper plr--70 plr_sm--15 plr_md--30">
            <div class="tab-default ">
              <PortfolioThree />
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <!-- End Gallery Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>
<script>
  import HeaderTwo from "../../components/header/Header";
  import BannerTwo from "../../components/slider/BannerTwo";
  import PortfolioThree from "../../components/portfolio/PortfolioThree";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      HeaderTwo,
      BannerTwo,
      PortfolioThree,
      Footer,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo-symbol-dark.png"),
      };
    },
  };
</script>
